<template>
  <ion-app>
    <ion-content>
      <EducationPatientHeader />
      <div class="center-center">
        <ion-spinner name="dots" duration="1200" />
      </div>
    </ion-content>
  </ion-app>
</template>

<script>
import EducationPatientHeader from "@/components/Education/EducationPatientHeader";
import { send as httpSend } from "@/services/Api";

export default {
  name: "ArticleView",
  components: {
    EducationPatientHeader
  },
  data() {
    return {
      originatingArticleId: this.$route.params.fromId,
      articleId: this.$route.params.toId
    };
  },
  created() {
    this.goToEducationPage();
  },
  methods: {
    async goToEducationPage() {
      const method = "GET";
      const path = document.config.getArticle.patient + this.originatingArticleId + "/subArticle/" + this.articleId;

      try {
        const result = await httpSend({ path, method });

        this.$router.replace({
          name: "educationHome",
          params: { id: result.data }
        });
      } catch (error) {
        khanSolo.log(error);
      }
    }
  }
};
</script>

<style scoped>
ion-app {
  --ion-background-color: #ffffff;
}
</style>
